@font-face {
  font-family: 'SangBleu Sunrise';
  src: url(/smithandfarrow/static/media/SangBleuSunrise-Light.55ee307b.woff2) format('woff2'),
    url(/smithandfarrow/static/media/SangBleuSunrise-Light.22c098bd.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SangBleu Sunrise';
  src: url(/smithandfarrow/static/media/SangBleuSunrise-Regular.122e1bf7.woff2) format('woff2'),
    url(/smithandfarrow/static/media/SangBleuSunrise-Regular.85d7a6a3.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SangBleu Sunrise';
  src: url(/smithandfarrow/static/media/SangBleuSunrise-Medium.3a7541ab.woff2) format('woff2'),
    url(/smithandfarrow/static/media/SangBleuSunrise-Medium.16ad7525.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

