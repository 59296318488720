@font-face {
  font-family: 'SangBleu Sunrise';
  src: url('../assets/fonts/SangBleuSunrise-Light.woff2') format('woff2'),
    url('../assets/fonts/SangBleuSunrise-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SangBleu Sunrise';
  src: url('../assets/fonts/SangBleuSunrise-Regular.woff2') format('woff2'),
    url('../assets/fonts/SangBleuSunrise-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SangBleu Sunrise';
  src: url('../assets/fonts/SangBleuSunrise-Medium.woff2') format('woff2'),
    url('../assets/fonts/SangBleuSunrise-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
